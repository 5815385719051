import { $axios } from '../config/axios'
import { AxiosRequestConfig } from 'axios'
import {
  Demand,
  RegDemand,
  ResPeerClass,
  AddDemand,
} from '@/model/industryManagement/demandInformation'
import Data from '@/model/common/Data'
class DemandApi {
  //发送验证码
  SendCode(data: unknown): Promise<string> {
    return $axios.post('/api/sendCode', data)
  }
  //手机号是否经过验证
  CheckPhone(phone: string): Promise<unknown> {
    return $axios.get('/api/sendCode', { params: { phone } })
  }
  //验证手机验证码
  Approve(data: unknown): Promise<string> {
    return $axios.post('/api/approve', data)
  }
  //分页查询需求信息
  DemandList(data: RegDemand): Promise<ResPeerClass> {
    return $axios.post('/api/demand/list', data)
  }
  //需求文件上传接口
  uploadFile(data: FormData, config?: AxiosRequestConfig): Promise<string> {
    return $axios.post('/api/file/uploadFile', data, config)
  }
  //分配
  DemandDistribute(data: unknown): Promise<Data> {
    return $axios.post('/api/demand/distribute', data)
  }
  //新增需求
  DemandAdd(data: AddDemand): Promise<Demand> {
    const host = window.location.host
    return $axios.post('/api/demand/customerAdd', Object.assign(data, { demandMsg: host }))
  }
  //新增需求
  DemandInternalAdd(data: AddDemand): Promise<Demand> {
    return $axios.post('/api/demand/add', data)
  }
  //获取历史需求信息
  Termination(data: unknown): Promise<unknown> {
    return $axios.post('/api/demand/termination', data)
  }
  //分页查询产品经理和产品部门
  ListDeptAndManager(data: unknown): Promise<{ data: Data[] }> {
    return $axios.post('/api/demand/listDeptAndManager', data)
  }
  //需求详情
  GetByNo(data: unknown): Promise<Demand> {
    return $axios.post('/api/demand/getByNo', data)
  }
  //更新详情
  DemandUpdate(data: unknown): Promise<Demand> {
    return $axios.post('/api/demand/update', data)
  }
  //再分配
  DemandRedistribute(data: unknown): Promise<Demand> {
    return $axios.post('/api/demand/redistribute', data)
  }
  //获取历史需求信息
  GetHistory(): Promise<Demand[]> {
    return $axios.post(`/api/demand/getHistory`, {})
  }
  //再分配
  CreateQuote(data: unknown): Promise<Demand> {
    return $axios.post('/api/demand/createQuote', data)
  }
  //更新需求评估信息
  UpdateEvaluate(data: unknown): Promise<Demand> {
    return $axios.post('/api/demand/updateEvaluate', data)
  }
  //转正
  Positive(data: unknown): Promise<Demand> {
    return $axios.post('/api/demand/positive', data)
  }
  //通过短链接获取长连接
  GetLong(key: unknown): Promise<string> {
    return $axios.post(`/api/smsUrl/getLong?key=${key}`, {})
  }
  //通过id下载
  Demanddownload(data: unknown): Promise<string> {
    return $axios.post('/zyz/file/demand/download', data)
  }
  //通过id下载
  DemanddownloadSec(id: unknown): Promise<string> {
    return $axios.get('/api/file/preview', { params: { id } })
  }
  //发送信息
  sendMessage(data: unknown): Promise<string> {
    return $axios.post('/api/demand/sendMessage', data)
  }
  // 关注
  demandFollow(data: unknown): Promise<string> {
    return $axios.post('/api/demandFollow/add', data)
  }
  // 取消关注
  demandFollowCancel(data: unknown): Promise<string> {
    return $axios.post('/api/demandFollow/cancel', data)
  }
  // 获取报价单状态
  GetSaAskpriceVOByQuoteNo(data: unknown): Promise<Data> {
    return $axios.post('/api/saleQuote/getSaAskpriceVOByQuoteNo', data)
  }
  //新版需求信息分页查询
  GetProposalList(data: unknown): Promise<Data> {
    return $axios.post('/api/proposal/list', data)
  }
}
let demandApi
export default (() => {
  if (demandApi) return demandApi
  demandApi = new DemandApi()
  return demandApi
})()
