/*
 * @Descripttion:
 * @version:
 * @Author: congsir
 * @Date: 2021-10-14 10:50:37
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-04-16 14:10:44
 */
/**
 * @method {constantRouterComponents} 路由对应组件表
 */
interface RouteData {
  [key: string]: string
}
export const constantRouterComponents: RouteData = {
  // '/defaultParentMenu': 'DefaultLayout',
  // 竞争分析模块
  '/competitiveAnalysis': 'index',
  // 竞争分析-同行信息模块
  '/competitiveAnalysis/peerData': '/competitiveAnalysis/peerData/peerData.vue',
  '/competitiveAnalysis/peerData/addPeerData':
    '/competitiveAnalysis/peerData/addUpdatePeerData/index.vue',
  '/competitiveAnalysis/peerData/updatePeerData/:id':
    '/competitiveAnalysis/peerData/addUpdatePeerData/index.vue',
  '/competitiveAnalysis/peerData/associatedPeerInformation/:id':
    '/competitiveAnalysis/peerData/associatedPeerInformation/associatedPeerInformation.vue',
  '/competitiveAnalysis/peerData/accountInformation/:id':
    '/competitiveAnalysis/peerData/accountInformation/index.vue',
  // 竞争分析-填报信息模块
  '/competitiveAnalysis/fillInformation':
    '/competitiveAnalysis/fillInformation/fillInformation.vue',
  '/competitiveAnalysis/fillInformation/addFill/:id':
    '/competitiveAnalysis/fillInformation/addUpdateFill/index.vue',
  '/competitiveAnalysis/fillInformation/updateFill/:id':
    '/competitiveAnalysis/fillInformation/addUpdateFill/index.vue',
  '/competitiveAnalysis/fillInformation/Detail/:id':
    '/competitiveAnalysis/fillInformation/addUpdateFill/index.vue',
  '/competitiveAnalysis/competingGoodsData':
    '/competitiveAnalysis/competingGoodsData/competingGoodsData.vue', // 销售报价
  '/competitiveAnalysis/competingGoodsData/competingGoodsDetail':
    '/competitiveAnalysis/competingGoodsData/competingGoodsDetail/competingGoodsDetail.vue', // 销售报价
  //价格分析
  '/competitiveAnalysis/analysisOfPrice':
    '/competitiveAnalysis/analysisOfPrice/analysisOfPrice.vue',
  '/competitiveAnalysis/analysisOfPrice/analysisOfPriceDetail/:id':
    '/competitiveAnalysis/analysisOfPrice/detail/index.vue',
  //行业管理
  '/industryManagement': 'index',
  // 行业管理-需求信息模块
  '/industryManagement/demandInformation': '/industryManagement/demandInformation/index.vue',
  '/industryManagement/demandAdd': '/industryManagement/demandInformation/demandAdd.vue',
  '/industryManagement/demandDeatil/:id':
    '/industryManagement/demandInformation/demandDeatil/index.vue',
  // 行业管理-提案信息模块
  '/industryManagement/proposalInformation': '/industryManagement/proposalInformation/index.vue',
  '/industryManagement/proposalDetail/:id':
    '/industryManagement/proposalInformation/proposalDetail/index.vue',
  // 行业管理-成果信息模块
  '/industryManagement/resultsManagement': '/industryManagement/resultsManagement/index.vue',
  '/industryManagement/resultsDetail/:id':
    '/industryManagement/resultsManagement/resultsDetail/index.vue',
  //客户管理
  '/customerManagement': 'index',
  '/customerManagement/customerInformation':
    '/customerManagement/customerInformation/customerInformation.vue',
  '/customerManagement/customerDetail/:id':
    '/customerManagement/customerInformation/customerDetail/customerDetail.vue',
  '/customerManagement/ipo': '/customerManagement/ipo/index.vue',
  // 资讯信息
  '/customerManagement/information': 'index',
  //上市行业资讯
  '/customerManagement/information/listedIndustry':
    '/customerManagement/information/listedIndustry/index.vue',
  // 上市客户财报
  '/customerManagement/information/financialStatement':
    '/customerManagement/information/financialStatement/index.vue',
  //客户热门公告
  '/customerManagement/information/announcement':
    '/customerManagement/information/announcement/index.vue',
  // 权限管理
  '/authorityManagement': 'index',
  '/authorityManagement/departmentInformation':
    '/authorityManagement/departmentInformation/index.vue',
  // 部门权限详情
  '/authorityManagement/departmentInformation/detail/:id':
    '/authorityManagement/departmentInformation/detail/index.vue',
  '/authorityManagement/personnelInformation':
    '/authorityManagement/personnelInformation/index.vue',
}
