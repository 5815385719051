// 路由守卫
import { isNavigationFailure, Router } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({ showSpinner: false })
// import { createStorage } from '@/utils/Storage'
import demandApi from '@/axios/api/demand'
import store from '@/store'
// const Storage = createStorage({ storage: localStorage })
// 测试路由数据
// import { routerToStorage, addRouterList } from './generatorRouters'

// import SystemApi from '@/http/apis/systemManage'
// import { MenuClass, MenuType } from '@/model/system/menu'
function getQueryString(name: string) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}
let getMenuFlag = true
export function createRouterGuards(router: Router): void {
  /**
   * 前置导航守卫
   * 文档：https://router.vuejs.org/zh/guide/advanced/navigation-guards.html
   *  1. 判断用户是否登录-没有登录则跳转到登录页面
   *  2. 如果跳转登录页面则放行
   *  3. 如果是错误页面则放行
   * @date 2021-01-26
   * @param {any} to 即将要进入的目标 路由对象
   * @param {any} from 当前导航正要离开的路由
   * @param {any} next 下一跳导航（必须调用）
   * @returns {any}
   */
  router.beforeEach((to, from, next) => {
    //判断是否短链接跳转  通过短链接获取长连接
    const reg = RegExp(/t.cn/)
    NProgress.start()
    const code = getQueryString('code')
    console.log(to.path)
    if (to.matched.length !== 0 && to.path !== '/home' && to.path !== '/404') {
      next()
    } else if (to.path.match(reg)) {
      console.log(to.path.split('/'))
      demandApi
        .GetLong(to.path.split('/')[2])
        .then((res) => {
          const path = res.split('/')
          path.splice(0, 1)
          next('/' + path.join('/'))
        })
        .catch(() => {
          next('/demandFormPhone')
        })
      // next({ ...to, replace: true })
    } else if (!code && !store.state.token) {
      if (location.pathname !== '/home') {
        store.commit('setRedirectUrl', location.pathname)
      }
      window.location.href = `${
        process.env.VUE_APP_login_uri
      }/oauth/authorize?response_type=code&client_id=${
        process.env.VUE_APP_client_id
      }&redirect_uri=${window.location.origin}&scope=all&state=${
        store.state.redirectUrl
      }&time=${new Date().getTime()}`
      next('/blank')
      // next()
      //查看是否获取到了菜单
    } else if (store.getters.getRouteList.length !== 0) {
      next()
    } else if (store.state.token && getMenuFlag) {
      getMenuFlag = false
      store
        .dispatch('getMenuByUserId')
        .then((LAYOUT) => {
          //拿到的路由添加
          console.log(LAYOUT)

          router.addRoute(LAYOUT)
          router.addRoute({
            path: '/:pathMatch(.*)',
            name: 'All',
            meta: {
              title: '错误页面',
              require: true,
            },
            redirect: '/404',
            component: () => import('@/layout/default.vue'),
            children: [],
          })
          next({ ...to, replace: true })
        })
        .catch(() => {
          if (to.path === '/404') {
            next()
          }
          next('/404')
        })
    } else {
      if (to.matched.length !== 0) {
        next()
      }
    }
  })
  router.afterEach((to, from, failure) => {
    if (isNavigationFailure(failure)) {
      console.log('failed navigation', failure)
    }
    NProgress.done()
  })

  router.onError((error) => {
    console.log(error, '路由错误')
  })
}
