
import { defineComponent, computed } from 'vue'
import Empty from '@/components/Empty'
import { YWatermark } from '@yhdfe/yui'
import store from '@/store'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import 'dayjs/locale/zh-cn'
export default defineComponent({
  components: { Empty, YWatermark },
  setup() {
    document.title = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
      ? '怡合达产品需求收集表'
      : '怡合达行业信息系统'

    // 获取水印内容
    const getWatermarkContent = computed(() => {
      return `${store.state.watermark}`
    })

    return {
      getWatermarkContent,
    }
  },
  data() {
    return {
      zhCN,
      img: require('@/assets/img/暂无数据.png'),
    }
  },
})
