import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Empty = _resolveComponent("Empty")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_config_provider = _resolveComponent("a-config-provider")!
  const _component_y_watermark = _resolveComponent("y-watermark")!

  return (_openBlock(), _createBlock(_component_y_watermark, {
    class: "watermark",
    content: _ctx.getWatermarkContent
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_config_provider, { locale: _ctx.zhCN }, {
        renderEmpty: _withCtx(() => [
          _createVNode(_component_Empty)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      }, 8, ["locale"])
    ]),
    _: 1
  }, 8, ["content"]))
}