/*
 * @Descripttion:
 * @version:
 * @Author: congsir
 * @Date: 2021-11-11 08:50:42
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-11-03 08:21:12
 */
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 导入插件
import directives from '@/plugins/directives'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.less'
import '@/assets/css/_root.scss'
import '@/assets/css/publicMain.scss'
import '@/assets/icon/iconfont.js'
import '@/assets/icon/iconfont.css'
import '@/assets/css/antStyle.less'
import '@/assets/css/mixin.less'
import {
  Button,
  Cell,
  CellGroup,
  Icon,
  Calendar,
  Form,
  Field,
  Uploader,
  Step,
  Steps,
  Col,
  Row,
  NavBar,
  ConfigProvider,
  DatetimePicker,
  Popup,
  Loading,
} from 'vant'
const components = [
  Button,
  Cell,
  CellGroup,
  Icon,
  Calendar,
  Form,
  Field,
  Uploader,
  Step,
  Steps,
  Col,
  Row,
  NavBar,
  ConfigProvider,
  DatetimePicker,
  Popup,
  Loading,
]
const app = createApp(App)
components.forEach((item) => {
  app.use(item)
})

app.use(store).use(router).use(directives).use(Antd).mount('#app')
