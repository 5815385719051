
import { PropType, defineComponent, computed } from 'vue'
import { EmptyTips } from './interface'

const TIPS_TYPE: { [x: string]: EmptyTips } = {
  empty: {
    tips: '暂无数据',
    operateTips: '快去添加',
  },
  netError: {
    tips: '网络已断开',
    operateTips: '点击重试',
  },
}

export default defineComponent({
  name: 'Empty',
  props: {
    type: {
      type: String as PropType<string>,
      default: 'empty',
    },
    tips: {
      type: String as PropType<string>,
      default: '',
    },
    operateTips: {
      type: String as PropType<string>,
      default: '',
    },
  },
  emits: ['operate'],
  setup(props, { emit, attrs }) {
    // 提示语
    const tipsTxt = computed(() =>
      props.tips
        ? props.tips
        : props.type
        ? TIPS_TYPE[props.type]
          ? TIPS_TYPE[props.type].tips
          : ''
        : ''
    )
    // 操作提示语
    const operateTipsTxt = computed(() =>
      props.operateTips
        ? props.operateTips
        : props.type
        ? TIPS_TYPE[props.type]
          ? TIPS_TYPE[props.type].operateTips
          : ''
        : ''
    )
    // 是否有操作
    const canOperate = !!attrs.onOperate
    // 操作回调
    const operate = () => {
      attrs.onOperate && emit('operate')
    }
    return {
      tipsTxt,
      operateTipsTxt,
      canOperate,
      operate,
    }
  },
})
