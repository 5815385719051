import store from '@/store'
import axios, { AxiosResponse } from 'axios'
import { MissingType } from '@/types/comm'
/** 实例化axios
 * -------------------------- */
const $axios = axios.create({
  baseURL: '/',
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' },
})
//重新获取token的开关
let isRefreshing = false
//需要重发的请求
let requests: MissingType = []
//控制loading显示
let reqNum = 0
/** 请求拦截器
 * -------------------------- */
$axios.interceptors.request.use(
  (config) => {
    //接口白名单
    const whiteList = [
      `/api/oauth/token?grant_type=authorization_code&redirect_uri=${window.location.origin}`,
      `/api/oauth/token?grant_type=refresh_token&refresh_token=${store.state.refreshToken}`,
    ]
    if (whiteList.indexOf(config.url as string) === -1) {
      config.headers.Authorization = 'bearer ' + store.state.token
      if (config.data?.noLoading) {
        delete config.data.noLoading
        if (Object.keys(config.data).length === 0 && config.method === 'get') {
          //如果data没有数据而且为get请求  去除data
          delete config.data
        }
      } else {
        config.showLoading = true
        reqNum += 1
        store.commit('setLoading', true)
      }
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

/** 响应拦截器
 * -------------------------- */
$axios.interceptors.response.use(
  (res) => {
    if (res.config.showLoading) reqNum -= 1
    if (reqNum === 0) store.commit('setLoading', false)
    if (res.data.constructor === ArrayBuffer) {
      try {
        const enc = new TextDecoder('utf-8')
        res.data = JSON.parse(enc.decode(new Uint8Array(res.data))) || {}
      } catch (e) {
        console.log(res.headers)
        if (res.headers['filename']) {
          return {
            data: res.data,
            filename: decodeURIComponent(res.headers['filename']),
          }
        }
        return res.data
      }
    }
    if (res.status !== 200)
      return Promise.reject(res.data?.rt_msg || `${res.config.url}接口请求错误`)
    if (res.data.rt_code === 0) {
      return res.data.data
    } else if (res.data?.rt_code == 90003 || res.data?.rt_code == 140005) {
      const config = res.config
      if (!isRefreshing) {
        isRefreshing = true
        return store
          .dispatch('getRefershToken')
          .then(() => {
            config.headers.Authorization = 'bearer ' + store.state.token
            config.baseURL = ''
            // 已经刷新了token，将所有队列中的请求进行重试
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            requests.forEach((cb: any) => cb(store.state.token))
            requests = []
            return $axios(config)
          })
          .catch((err) => {
            if (err === '刷新token错误') {
              if (location.pathname !== '/home') {
                store.commit('setRedirectUrl', location.pathname)
              }
              store.commit('setToken', '')
              store.commit('setRefreshToken', '')
              window.location.href = `${
                process.env.VUE_APP_login_uri
              }/oauth/authorize?response_type=code&client_id=${
                process.env.VUE_APP_client_id
              }&redirect_uri=${window.location.origin}&scope=all&state=${
                store.state.redirectUrl
              }&time=${new Date().getTime()}`
            }
            console.log('跳转了')
            store.commit('setToken', '')
            store.commit('setRefreshToken', '')
            window.location.href = `${
              process.env.VUE_APP_login_uri
            }/oauth/authorize?response_type=code&client_id=${
              process.env.VUE_APP_client_id
            }&redirect_uri=${window.location.origin}&scope=all&state=${
              store.state.redirectUrl
            }&time=${new Date().getTime()}`
            return Promise.reject(res.data.rt_msg)
          })
          .finally(() => {
            isRefreshing = false
          })
      } else {
        // 正在刷新token，将返回一个未执行resolve的promise
        return new Promise((resolve) => {
          // 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
          requests.push((token: string) => {
            config.baseURL = ''
            config.headers.Authorization = 'bearer ' + token
            resolve($axios(config))
          })
        })
      }
    } else {
      return Promise.reject(res.data?.rt_msg || `${res.config.url}接口请求错误`)
    }
  },
  (error) => {
    if (error.config?.showLoading) reqNum -= 1
    if (reqNum === 0) store.commit('setLoading', false)
    return Promise.reject(error.response?.data?.rt_msg || `接口请求错误`)
  }
)

export { $axios, AxiosResponse }
