import { $axios } from '../config/axios'
import Data from '@/model/common/Data'
import { MenuClass } from '@/model/system/menu'
import { UserClass } from '@/model/system/user'
export interface UserLogin {
  /**
   *@param {string} loginName 登录名称
   *@param {string} loginPassword 登录密码
   */
  loginName: string
  loginPassword: string
}
interface LoginRes {
  id: string
  loginName: string
  token: string
}
//获取token的返回接口定义

class UserApi {
  login(data: UserLogin): Promise<LoginRes> {
    return $axios.post('/api/auth/login', data)
  }
  getToken(code: string): Promise<Data> {
    return $axios.get(
      `/api/oauth/token?grant_type=authorization_code&redirect_uri=${window.location.origin}`,
      {
        headers: {
          Authorization: 'basic ' + process.env.VUE_APP_client_basic,
        },
        params: {
          code: code,
        },
      }
    )
  }
  refreshToken(refreshToken: string): Promise<Data> {
    return $axios.get(`/api/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`, {
      headers: {
        Authorization: 'basic ' + process.env.VUE_APP_client_basic,
      },
    })
  }
  // 获取用户权限
  getMenuByUserId(): Promise<MenuClass[]> {
    return $axios.post('/api/sysRoleUser/getMenuByUserId', {})
  }
  //获取个人信息
  getDepartmentInfo(): Promise<UserClass> {
    return $axios.post('/api/user/getTokenByUserInfo', { noLoading: true })
  }
  //上传接口
  uploadFile(data: FormData): Promise<Data> {
    return $axios.post('/api/file/uploadFile', data)
  }
  //修改密码
  updatePassword(data: unknown) {
    return $axios.post('/api/userAccount/updatePassword', data)
  }
}
// 单列模式返回对象
let userApi
export default (() => {
  if (userApi) return userApi
  userApi = new UserApi()
  return userApi
})()
