import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-482f47fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "result" }
const _hoisted_2 = { class: "tips" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "icon", {}, () => [
      _createElementVNode("div", {
        class: _normalizeClass(['icon', _ctx.type])
      }, null, 2)
    ], true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.tipsTxt), 1),
      _createTextVNode("    "),
      (_ctx.canOperate)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            class: _normalizeClass(['operate', { 'can-operate': _ctx.canOperate }]),
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.operate && _ctx.operate(...args)))
          }, _toDisplayString(_ctx.operateTipsTxt), 3))
        : _createCommentVNode("", true)
    ]),
    _renderSlot(_ctx.$slots, "extra", {}, undefined, true)
  ]))
}