import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { createRouterGuards } from './routerGuards'
export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页',
      require: true,
    },
    redirect: '/home',
    component: () => import('@/layout/index.vue'),
    children: [],
  },
  {
    path: '/home',
    name: 'HomePage',
    meta: {
      title: '首页',
      require: false,
    },
    component: () => import('@/layout/default.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: '首页',
        },
        component: () => import('@/views/home/Home.vue'),
      },
      // {
      //   path: '/competitiveAnalysis',
      //   name: 'competitiveAnalysis',
      //   meta: {
      //     title: '竞争分析',
      //   },
      //   component: () => import('@/layout/index.vue'),
      //   children: [
      //     {
      //       path: '/competitiveAnalysis/peerData',
      //       name: 'peerData',
      //       meta: {
      //         title: '同行信息',
      //       },
      //       component: RouterReplaceComp(
      //         () => import('@/views/competitiveAnalysis/peerData/index.vue')
      //       ),
      //       children: [
      //         {
      //           path: '/competitiveAnalysis/peerData/addPeerData',
      //           name: 'addPeerData',
      //           meta: {
      //             title: '新增同行',
      //           },
      //           component: () =>
      //             import('@/views/competitiveAnalysis/peerData/addUpdatePeerData/index.vue'),
      //         },
      //         {
      //           path: '/competitiveAnalysis/peerData/updatePeerData/:id',
      //           name: 'updatePeerData',
      //           meta: {
      //             title: '编辑同行',
      //           },
      //           component: () =>
      //             import('@/views/competitiveAnalysis/peerData/addUpdatePeerData/index.vue'),
      //         },
      //         {
      //           path: '/competitiveAnalysis/peerData/associatedPeerInformation/:id',
      //           name: 'associatedPeerInformation',
      //           meta: {
      //             title: '物料类别关联同行',
      //           },
      //           component: () =>
      //             import(
      //               '@/views/competitiveAnalysis/peerData/associatedPeerInformation/associatedPeerInformation.vue'
      //             ),
      //         },
      //         {
      //           path: '/competitiveAnalysis/peerData/accountInformation/:id',
      //           name: 'accountInformation',
      //           meta: {
      //             title: '账号信息',
      //           },
      //           component: () =>
      //             import(
      //               '@/views/competitiveAnalysis/peerData/accountInformation/accountInformation.vue'
      //             ),
      //         },
      //       ],
      //     },
      //     {
      //       path: '/competitiveAnalysis/fillInformation',
      //       name: 'fillInformation',
      //       meta: {
      //         title: '填报信息',
      //       },
      //       component: RouterReplaceComp(
      //         () => import('@/views/competitiveAnalysis/fillInformation/fillInformation.vue')
      //       ),
      //       children: [
      //         {
      //           path: '/competitiveAnalysis/fillInformation/addFill/:id',
      //           name: 'addFill',
      //           meta: {
      //             title: '物料填报',
      //           },
      //           component: () =>
      //             import(
      //               '@/views/competitiveAnalysis/fillInformation/addUpdateFill/addUpdateFill.vue'
      //             ),
      //         },
      //         {
      //           path: '/competitiveAnalysis/fillInformation/updateFill/:id',
      //           name: 'UpdateFill',
      //           meta: {
      //             title: '物料修改',
      //           },
      //           component: () =>
      //             import(
      //               '@/views/competitiveAnalysis/fillInformation/addUpdateFill/addUpdateFill.vue'
      //             ),
      //         },
      //         {
      //           path: '/competitiveAnalysis/fillInformation/Detail/:id',
      //           name: 'DetailFill',
      //           meta: {
      //             title: '物料详情',
      //           },
      //           component: () =>
      //             import(
      //               '@/views/competitiveAnalysis/fillInformation/addUpdateFill/addUpdateFill.vue'
      //             ),
      //         },
      //       ],
      //     },
      //     {
      //       path: '/competitiveAnalysis/competingGoodsData',
      //       name: 'competingGoods',
      //       meta: {
      //         title: '竞品信息',
      //       },
      //       component: () => import('@/views/competitiveAnalysis/competingGoodsData/index.vue'),
      //     },
      //   ],
      // },
    ],
    // children: [customerRouter, systemRouter],
  },
  {
    path: '/blank',
    name: 'blank',
    component: () => import('@/layout/index.vue'),
  },
  {
    path: '/requirementsGathering/demandForm',
    name: 'demandForm',
    component: () => import('@/views/requirementsGathering/demandForm.vue'),
  },
  {
    path: '/requirementsGathering/success',
    name: 'success',
    component: () => import('@/views/requirementsGathering/success.vue'),
  },
  {
    path: '/requirementsGathering/authenticationInformation',
    name: 'authenticationInformation',
    component: () => import('@/views/requirementsGathering/authenticationInformation.vue'),
  },
  {
    path: '/demandFormPhone',
    name: 'demandFormPhone',
    component: () => import('@/views/demandForm/demandForm.vue'),
  },
  {
    path: '/success',
    name: 'successPhone',
    component: () => import('@/views/demandForm/success.vue'),
  },
  {
    path: '/phoneValidation',
    name: 'phoneValidation',
    component: () => import('@/views/phoneValidation/phoneValidation.vue'),
  },
  {
    path: '/submitRecord/list',
    name: 'submitRecordList',
    component: () => import('@/views/submitRecord/list.vue'),
  },
  {
    path: '/submitRecord/detail/:id',
    name: 'submitRecordDetail',
    component: () => import('@/views/submitRecord/detail.vue'),
  },
  {
    path: '/userCenter',
    name: 'userCenter',
    component: () => import('@/views/userCenter/index.vue'),
  },
  {
    path: '/404',
    name: 'Error',
    meta: {
      title: '错误页面',
      require: true,
    },
    component: () => import('@/layout/default.vue'),
    children: [],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
// 创建路由守卫
createRouterGuards(router)
export default router
