import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as COOKIE from 'js-cookie'
import { MenuClass } from '@/model/system/menu'
import router from '@/router'
import userApi from '@/axios/api/user'
import { message } from 'ant-design-vue'
import { routes } from '@/router/index'
import { constantRouterComponents } from '@/router/constantRouterComponents'
import Data from '@/model/common/Data'
import RouterReplaceComp from '@/utils/routerReplaceSelf.js'
const constantRouterComponent = constantRouterComponents
import index from '@/layout/index.vue'
import { AddDemand } from '@/model/industryManagement/demandInformation'
import { MissingType } from '@/types/comm'
export const loadView = (view: string): unknown => {
  // return view === 'DefaultLayout'
  //   ? DefaultLayout
  //   : (resolve: any) => require([`@/views/${view}`], resolve)
  if (view === 'index') return index
  return () => import(`@/views${view}`)
}
interface RouteItem {
  fullPath: string
  hash?: MissingType
  meta?: MissingType
  name?: string
  params?: Data
  path?: string
  query?: Data
}
export default createStore({
  state: {
    auth: '', // 用户标识
    loading: false,
    token: '', //用户token
    code: '', //登录获得的code
    refreshToken: '', //用户refreshToken
    redirectUrl: '', //用户登录后重新跳转的页面
    menuList: [], //用户拥有的菜单权限
    routeNameList: ['home'] as string[], //用户拥有的路由地址
    oldTime: 0, //上次刷新token的时间
    demandForm: {} as AddDemand, //保存的需求收集表单
    regForm: {} as Data,
    expiresIn: 3600, //token失效时间
    resultsDetailForm: {}, //成果详情数据
    tabsView: [
      {
        path: '/home',
        fullPath: '/home',
        name: 'home',
        meta: {
          title: '首页',
          require: false,
        },
      },
    ] as RouteItem[], //tab栏
    watermark: '',
  },
  //vuex持久化
  plugins: [
    createPersistedState({
      paths: [
        'auth',
        'token',
        'refreshToken',
        'redirectUrl',
        'oldTime',
        'demandForm',
        'expiresIn',
        'resultsDetailForm',
        'tabsView',
      ],
    }),
  ],
  mutations: {
    addTabsView(state, route) {
      console.log(route)
      //检查去重
      const isExists = state.tabsView.some((item, index) => {
        if (item.path !== route.path && item.name === route.name) {
          state.tabsView[index] = route
        }
        return item.name === route.name
      })
      if (!isExists) {
        if (route.name === '首页') {
          state.tabsView.splice(-state.tabsView.length, 0, route)
        } else if (route.fullPath !== '/') {
          state.tabsView.push(route)
        }
      }
    },
    delTabsView(state, Obj) {
      const index = state.tabsView.findIndex((item) => item.name === Obj.name)
      state.tabsView.splice(index, 1)
      console.log(state.tabsView[index - 1]?.fullPath, 'state.tabsView')
      if (Obj.isCurrent) router.push(state.tabsView[index - 1]?.fullPath || '/home')
    },
    addRouteNameList(state, name: string) {
      state.routeNameList.push(name)
    },
    setResultsDetailForm(state, form) {
      state.resultsDetailForm = form
    },
    setRegForm(state, [key, form]: [key: string, form: unknown]) {
      state.regForm[key] = JSON.parse(JSON.stringify(form))
      console.log(state.regForm)
    },
    setDemandForm(state, demandForm) {
      state.demandForm = demandForm
    },
    setOldTime(state, oldTime) {
      state.oldTime = oldTime
    },
    // 保存用户token
    setAuth(state, auth) {
      state.auth = auth
    },
    // 清除用户资料
    LOGOUT(state) {
      state.auth = ''
      COOKIE.remove('token')
      router.push({ name: 'Login' })
    },
    //设置用户全局loading开关
    setLoading(state, bool) {
      state.loading = bool
    },
    //设置token
    setToken(state, token) {
      state.token = token
    },
    //设置refreshToken
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken
    },
    //设置code
    setCode(state, code) {
      state.code = code
    },
    //设置redirectUrl
    setRedirectUrl(state, redirectUrl) {
      state.redirectUrl = redirectUrl
    },
    //设置menuList
    setMenuList(state, menuList) {
      state.menuList = menuList
    },
    setExpiresIn(state, expiresIn) {
      state.expiresIn = expiresIn
    },
    setWatermark(state, watermark) {
      state.watermark = watermark
    },
  },
  getters: {
    //从菜单列表中 过滤出菜单  渲染菜单栏
    getMenu: (state): MenuClass[] => {
      // //过滤菜单的方法
      const detailChild = (child: MenuClass[]) => {
        if (!child) return []
        child.sort(function (a: MenuClass, b: MenuClass) {
          return (a.sort as number) - (b.sort as number)
        })
        return child.filter((item: MenuClass) => {
          if (
            item.urlType === 1 &&
            item.isEnable !== 0 &&
            item.subLinkMenu &&
            item.subLinkMenu.length
          ) {
            item.subLinkMenu = detailChild(item.subLinkMenu as MenuClass[])
          }
          return item.urlType === 1 && item.isEnable !== 0
        })
      }
      //需要深拷贝否则覆盖原数据
      const newArr = JSON.parse(JSON.stringify(state.menuList))
      //过滤
      newArr.sort(function (a: MenuClass, b: MenuClass) {
        return (a.sort as number) - (b.sort as number)
      })
      const arr1: MenuClass[] = newArr.filter((item: MenuClass) => {
        if (
          item.urlType === 1 &&
          item.isEnable !== 0 &&
          item.subLinkMenu &&
          item.subLinkMenu.length
        ) {
          item.subLinkMenu = detailChild(item.subLinkMenu as MenuClass[])
        }
        return item.urlType === 1 && item.isEnable !== 0
      })
      return arr1
    },
    //从菜单列表中过滤出路由数据
    getRouteList(state) {
      //需要被RouterReplaceComp封装的路由
      const RouterReplaceCompList = [
        'fillInformation',
        'peerData',
        'competingGoodsData',
        'demandInformation',
        'resultsManagement',
        'customerInformation',
        'proposalInformation',
        'analysisOfPrice',
        'departmentInformation',
      ]
      const routeFilter = (items: MenuClass) => {
        if (items.subLinkMenu) {
          items.subLinkMenu.sort(function (a: MenuClass, b: MenuClass) {
            return (a.sort as number) - (b.sort as number)
          })
        }
        const path: string = items.targetUrl as string
        let name = (items.targetUrl as string).split('/')[
          (items.targetUrl as string).split('/').length - 1
        ]
        if (name === ':id') {
          name = (items.targetUrl as string).split('/')[
            (items.targetUrl as string).split('/').length - 2
          ]
        }
        if (items.urlType === 1 && name) {
          state.routeNameList.push(name)
        }
        const index = RouterReplaceCompList.indexOf(name)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const route: any = {
          path: items.targetUrl,
          name: name,
          meta: {
            title: items.menuName,
          },
          component:
            index === -1
              ? loadView(constantRouterComponent[path])
              : RouterReplaceComp(loadView(constantRouterComponent[path])),
          children: [],
        }
        if (items.subLinkMenu?.length !== 0) {
          items.subLinkMenu = items.subLinkMenu?.filter(
            (item) => item.urlType === 1 && item.isEnable === 1
          ) as MenuClass[]
          route.children = items.subLinkMenu?.map((item) => routeFilter(item))
        }
        return route
      }
      //需要深拷贝否则覆盖原数据
      const newArr = JSON.parse(JSON.stringify(state.menuList))
      return newArr.map((item: MenuClass) => routeFilter(item))
    },
    //从菜单列表中过滤出用户拥有的按钮权限
    getButtonObj(state) {
      const newArr = JSON.parse(JSON.stringify(state.menuList))
      const buttonObj: Data = {}
      const buttonFilter = (item: MenuClass) => {
        if (item.urlType === 2) {
          buttonObj[item.targetUrl as string] = item.icon || true
        }
        if (item.subLinkMenu?.length !== 0) {
          item.subLinkMenu?.forEach((items) => {
            buttonFilter(items)
          })
        }
      }
      newArr.forEach((item: MenuClass) => {
        buttonFilter(item)
      })
      return buttonObj
    },
    getTabsView(state) {
      return state.tabsView
    },
  },
  actions: {
    //获取token的接口
    getToken(content) {
      return new Promise((resolve, reject) => {
        userApi
          .getToken(content.state.code)
          .then((res) => {
            //成功了把code重置为空
            content.commit('setCode', '')
            //设置token
            content.commit('setToken', res['access_token'])
            //设置refresh_token
            content.commit('setRefreshToken', res['refresh_token'])
            content.commit('setExpiresIn', res['expires_in'])
            content.commit('setOldTime', new Date().getTime())
            resolve(res)
          })
          .catch(() => {
            message.error('获取token错误')
            reject('获取token错误')
          })
      })
    },
    //获取用户权限
    getMenuByUserId: (content) => {
      return new Promise((resolve, reject) => {
        userApi
          .getMenuByUserId()
          .then((res) => {
            //过滤出情报中心的权限数据 保存在menuList
            const id =
              process.env.VUE_APP_MODE === 'prod' ? '2539820190526096760' : '-7316350163619262787'
            const menuList = res.find((item) => item.id === id)?.subLinkMenu as MenuClass[]
            if (!menuList) {
              content.commit('setMenuList', [])
              message.warning('无权限访问此系统，请向管理员获取权限。')
              reject('404')
              return
            }
            content.commit('setMenuList', menuList)
            console.log(content.state.menuList)
            const LAYOUT = routes.find((item) => item.name === 'HomePage')
            console.log(content.getters.getButtonObj)
            //拼接两个数组
            if (LAYOUT) LAYOUT.children = LAYOUT.children?.concat(content.getters.getRouteList)
            content.state.tabsView = content.state.tabsView.filter((item) =>
              content.state.routeNameList.includes(item.name as string)
            )
            //在路由守卫中添加动态路由
            resolve(LAYOUT)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    //re
    getRefershToken: (content) => {
      return new Promise((reslove, reject) => {
        userApi
          .refreshToken(content.state.refreshToken)
          .then((res) => {
            //成功了把code重置为空
            content.commit('setCode', '')
            //设置token
            content.commit('setToken', res['access_token'])
            //设置refresh_token
            content.commit('setRefreshToken', res['refresh_token'])
            content.commit('setExpiresIn', res['expires_in'])
            content.commit('setOldTime', new Date().getTime())
            reslove(res)
          })
          .catch(() => {
            message.error('刷新token错误')
            reject()
          })
      })
    },
    //退出登录
    logout(content) {
      content.commit('setCode', '')
      content.commit('setToken', '')
      content.commit('setRefreshToken', '')
      content.commit('setOldTime', 0)
      COOKIE.remove('SESSION')
      window.location.href = `${process.env.VUE_APP_login_uri}/oauth/authorize?response_type=code&client_id=${process.env.VUE_APP_client_id}&redirect_uri=${window.location.origin}&scope=all&state=${content.state.redirectUrl}`
    },
  },
  modules: {},
})
