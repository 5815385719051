import { App } from 'vue'

function preventReClick(app: App): void {
  // 节流指令
  app.directive('preventReClick', {
    mounted(el, binding) {
      let timer: number | undefined
      el.addEventListener('click', () => {
        console.log(el)

        if (!el.disabled) {
          el.disabled = true
          clearTimeout(timer)
          timer = setTimeout(() => {
            el.disabled = false
          }, binding.value || 2000) // 传入绑定值就使用，默认1000毫秒内不可重复触发
        }
      })
    },
  })
  // 防抖回调
  app.directive('debounce', {
    mounted(el, binding) {
      let timer: number | undefined
      let option = true
      el.addEventListener('click', () => {
        if (timer) {
          clearTimeout(timer)
        }
        if (option) {
          binding.value()
          option = false
        }
        timer = setTimeout(() => {
          option = true
        }, 2000) // 传入绑定值就使用，默认2000毫秒内不可重复触发
      })
    },
  })

  // element-ui select 下拉加载自定义指令
  app.directive('selectLoadMore', {
    mounted(el, binding) {
      const child = el.querySelector('.select-trigger')
      const id = child.getAttribute('ariadescribedby')
      const poper = document.getElementById(id) as HTMLElement
      const SELECTDOWN_DOM = poper.querySelector(
        '.el-scrollbar .el-select-dropdown__wrap'
      ) as HTMLElement
      // 这里不能使用箭头函数！
      // eslint-disable-next-line func-names
      SELECTDOWN_DOM.addEventListener('scroll', function () {
        /**
         * scrollHeight 获取元素内容高度(只读)
         * scrollTop 获取或者设置元素的偏移值,
         *  常用于:计算滚动条的位置, 当一个元素的容器没有产生垂直方向的滚动条, 那它的scrollTop的值默认为0.
         * clientHeight 读取元素的可见高度(只读)
         * 如果元素滚动到底, 下面等式返回true, 没有则返回false:
         * ele.scrollHeight - ele.scrollTop === ele.clientHeight;
         */
        const CONDITION = this.scrollHeight - this.scrollTop <= this.clientHeight
        if (CONDITION) {
          binding.value()
        }
      })
    },
  })
}

export default {
  install: preventReClick,
}
